import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;


import { AddToFavoritesButton, AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCardProduct.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  // if (price && price.currency === currency) {
  if (price && currency) {
    // const formattedPrice = formatMoney(intl, price);
    const formattedPrice = formatMoney(intl, new Money(price.amount, currency));
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
    </div>
  );
};

export const ListingCardProductComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    currentUser,
  } = props;

  const renderSizes = [
    '(max-width: 549px) 100vw',
    '(max-width: 767px) 50vw',
    `(max-width: 1439px) 26vw`,
    `(max-width: 1920px) 18vw`,
    `14vw`,
  ].join(', ');

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const currentListingId = currentListing?.id?.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',

  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const isOwnListing = currentListing?.author?.id?.uuid === currentUser?.id?.uuid;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id: currentListingId, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={1}
        height={1}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
        <div className={css.favoriteButtonWrapper}>
          <AddToFavoritesButton
            listingId={currentListingId}
            currentUser={currentUser}
            isOwnListing={isOwnListing}
          />
        </div>
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(`${publicData.brand} ${title}`, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </div>

        <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
        <div className={css.title}>
          {`SIZE - ${publicData.size}`}
        </div>

      </div>
    </NamedLink>
  );
};

ListingCardProductComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  showAuthorInfo: true,
};

ListingCardProductComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  // Responsive image sizes hint
  renderSizes: string,
};

export default injectIntl(ListingCardProductComponent);
